.formBOX .el-form-item__content {
  width: 50%;
}
.formBOX .el-input-number {
  width: 100%;
}
.formBOX .form-itemteache .el-form-item__content {
  display: flex;
  align-items: center;
  margin-left: 0;
}
.formBOX .form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formBOX .form-btn .el-button {
  width: 10rem;
}
.el-cascader {
  width: 100% !important;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.fileNameUpload .upload-btn {
  height: 40px !important;
}
.fileNameUpload .upload-btn .el-upload {
  height: 40px !important;
  border: none !important;
}
